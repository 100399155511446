<template>
  <div>
    <c-tab
      ref="ctomissTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :plan.sync="plan"
          :contentHeight="contentHeight"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'jobStressAssessmentResultDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      plan: {
        heaJobStressPlanId: '',  // 직무스트레스 계획 일련번호
        plantCd: null,  // 사업장
        planYear: '',  // 년도
        jobStressPlanName: '',  // 평가계획명
        assessmentStartDate: '',  // 평가 시작일
        assessmentEndDate: '',  // 평가 종료일
        period: [], // 평가기간
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        users: [], // 평가자
        centers: [], // 중앙값
        depts: [], // 중앙값
        imprs: [], // 중앙값
        resultTable: [],
      },
      tab: 'status',
      tabItems: [
        { name: 'status', icon: 'assignment_turned_in', label: '평가결과', component: () => import(`${'./jobStressAssessmentResultStatus.vue'}`) },
        { name: 'bad', icon: 'verified', label: '관리대상군', component: () => import(`${'./jobStressAssessmentResultDeptUser.vue'}`) },
        { name: 'impr', icon: 'engineering', label: '개선', component: () => import(`${'./jobStressAssessmentResultImpr.vue'}`) },
      ],
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.jobStress.plan.status.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.heaJobStressPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.plan, _result.data);

        // 평가결과표
        this.plan.resultTable = [];
        // this.$comm.getComboItems('HEA_STRESS_CHECK_CLASS_CD').then(_result => {
        // });
        this.$_.forEach(this.plan.depts, dept => {
          this.$_.forEach(this.plan.centers, center => {
            let _score = 0;
            switch(center.heaStressCheckClassCd) {
              case 'add_circle': // 추가/신규 amber-7
                break;
              case 'HCC0000001': // 물리적환경
                _score = dept.physicalEnv;
                break;
              case 'HCC0000005': // 직무요구
                _score = dept.jobDemand;
                break;
              case 'HCC0000010': // 직무자율
                _score = dept.jobAutonomy;
                break;
              case 'HCC0000015': // 관계갈등
                _score = dept.relationship;
                break;
              case 'HCC0000020': // 직무 불안정
                _score = dept.jobInsecurity;
                break;
              case 'HCC0000025': // 조직체계
                _score = dept.organizational;
                break;
              case 'HCC0000030': // 보상부적절
                _score = dept.inadequate;
                break;
              case 'HCC0000035': // 직장문화
                _score = dept.workplace;
                break;
              default:
                // nothing..
                break;
            }
            this.plan.resultTable.push({
              deptCd: dept.deptCd,
              deptName: dept.deptName,
              heaStressCheckClassName: center.heaStressCheckClassName,
              score: _score,
              companyMale: center.companyMale,
              companyFemale: center.companyFemale,
              company: center.company,
              koreaMale: center.koreaMale,
              koreaFemale: center.koreaFemale,
              korea: center.korea,
            })
          })
        })
      },);
    },
  }
};
</script>
